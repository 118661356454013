<template>
<div>
    <v-container class="contact_box relative-center mt-10" v-show="show">
        <h1 class="fuchi_shadow f-20 mb-5">
            お問合せ
        </h1>
        <v-card class="pa-6">
            <v-form ref="form">
                <v-text-field v-model="zip" :rules="zipRules" label="郵便番号" outlined type="text" maxlength="8" class="zip" />
                <v-text-field v-model="address" label="ご住所*" :rules="requredRules" type="text" outlined maxlength="255" /><br>
                <v-text-field v-model="company" label="御社名" outlined type="text" maxlength="255" />

                <v-text-field v-model="name" :rules="requredRules" label="ご氏名*" outlined type="text" maxlength="255" />

                <v-text-field v-model="email" outlined label="メールアドレス*" maxlength="255" required :error-messages="emailErrors"  @input="$v.email.$touch()" @blur="$v.email.$touch()"></v-text-field>

                <v-text-field v-model="tel" label="お電話番号" type="text" outlined maxlength="15" class="tel" />

                <v-textarea v-model="contact" :rules="requredRules" label="お問合せ内容*" type="text" outlined maxlength="1000" />

                <v-btn color="#E64A19" class="mt-3 mb-2" dark x-large @click="confirm()">
                    お問合せ内容の確認
                </v-btn>
            </v-form>
        </v-card>
    </v-container>
    <v-dialog width="600" v-model="dialog">
        <v-card class="pa-4">
            <h3 class="f-gray mb-6">お問合せ内容の確認</h3>
            <div class="text-left">
                <div>
                    <div class="bold mt-3">ご住所</div>
                    <p class="mt-1">
                        <span v-show="zip">〒{{zip}}<br></span>
                        {{address}}
                    </p>
                    <hr />
                </div>
                <div v-show="company">
                    <div class="bold mt-3">御社名</div>
                    <p class="mt-1">
                        {{company}}
                    </p>
                    <hr />
                </div>
                <div>
                    <div class="bold mt-3">ご氏名</div>
                    <p class="mt-1">
                        {{name}}
                    </p>
                    <hr />
                </div>
                <div>
                    <div class="bold mt-3">メールアドレス</div>
                    <p class="mt-1">
                        {{email}}
                    </p>
                    <hr />
                </div>
                <div v-show="tel">
                    <div class="bold mt-3">お電話番号</div>
                    <p class="mt-1">
                        {{tel}}
                    </p>
                    <hr />
                </div>

                <div>
                    <div class="bold mt-3">お問合せ内容*</div>
                    <p v-text="contact" class="mt-1 nbr" />
                    <hr />
                </div>
            </div>
            <div class="text-center mt-10">
                <v-btn color="#E64A19" class="mt-3 mb-2 submit_btn" dark x-large @click="submit()" :class="{noevent:loading==1}">
                    <v-progress-circular v-show="loading==1" indeterminate color="white"></v-progress-circular>
                    <span v-show="loading==0">送信する</span>
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
    <Loading v-show="loading!=0" />
    <Snackbar ref="snack" />
</div>
</template>

<script>
import Zip from "@/mixins/zip"
import Form from "@/mixins/form"
import Snackbar from "@/components/snackbar.vue"
import Loading from "@/components/loading.vue"

import axios from "axios"

export default {
    mixins: [Zip, Form, ],
    data() {
        return {
            dialog: false,
            loading: false,
            show:false
        }
    },
    components: {
        Loading,
        Snackbar
    },
        created(){
          if(!this.Info.address||(this.Info.type==1&&this.Station.length==0)||!this.Info.starthh){
            window.location.href = "/notfound";
            return false;
        }
        this.show=true
    },
    methods: {
        async confirm() {
            this.$refs.form.validate()
            this.$v.$touch()
            if (this.$v.$invalid || !this.$refs.form.validate()) {
                return false
            }
            this.dialog = true
        },
        async submit() {
            this.loading = 1

            let data = {}

            data["zip"] = this.zip
            data["address"] = this.address
            data["name"] = this.name
            data["email"] = this.email
            data["tel"] = this.tel
            data["contact"] = this.contact

            const response = await axios.post("/contact/send", data)

            if (response.data.ok) {
                this.$refs.snack.snack = true
                this.$refs.snack.message = "送信しました"
                this.$refs.form.reset()
                this.$v.$reset()
            } else {
                alert("エラーが発生しました")
            }
            this.dialog = false
            this.loading = 0
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/contact.scss";
</style>
